import React from 'react'

type MailtoType = {
    href: string,
    icon?: JSX.Element,
    type?: string,
    className?: string,
}
const Mailto: React.FC<MailtoType> = ({ href, icon, type, className, children }) => {
    
    const reverseText = (text: string) => text.split('').reverse().join('')
    const linkType = (type) ? type + ':' : ''
    const content = icon ? (
        <>{ reverseText(href) } { icon }</>
    ) : reverseText(href)

    return (
        <a className={ className }
            style={{ unicodeBidi: 'bidi-override', direction: 'rtl' }}
            onClick={ (event) => {
                event.preventDefault()
                window.location.href = linkType + href
            }}
            href="#">
            { (children) ? children : content }
        </a>
    )
}

export default Mailto