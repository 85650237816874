import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const Header: React.FC = ({ children }) => {
    
    const { file } = useStaticQuery(graphql`
        query {
            file(
                relativePath: { eq: "bg.jpg" }
            ) {
                base
                childImageSharp {
                    fluid(
                        maxWidth: 1200,
                        quality: 85,
                        srcSetBreakpoints: [1200],
                        sizes: "(max-width: 1023px) 736px, (max-width: 1279px) 976px, 1200px",
                        traceSVG: {
                            color: "#00246E",
                            background: "#476BB5"
                        }
                    ) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
        }
    `)

    return (
        <header className="block w-full relative py-8 px-6 overflow-hidden bg-blue-700
            sm:py-24 sm:px-16
            lg:fixed lg:h-full lg:w-1/3 xxl:w-smaller
            lg:py-32 lg:px-12">
            <div className="w-full absolute inset-0"
                role="presentation">
                <Img fluid={ file.childImageSharp.fluid } alt=""
                    style={{ height: '100%' }} />
            </div>
            <div className="relative w-full text-center lg:text-right
                lg:h-full lg:flex lg:flex-col lg:justify-between lg:items-end">
                { children }
            </div>
        </header>
    )
}

export default Header