import React from 'react'

import 'theme/global.css'
import Header from './header'
import Mailto from '../mailto'

const mail = (
    <svg className="inline-block h-6 w-6 group-hover:text-indigo-300"
        xmlns="http://www.w3.org/2000/svg"
        fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
    </svg>
)

const Layout: React.FC = ({ children }) => {

    const [visibleSection, setVisibleSection] = React.useState('about')
    let visibleSections: string[] = []

    const sectionIds = ['about', 'web-design', 'book-design', 'logos', 'contact']
    const sectionLabels = ['About', 'Web Design', 'Book Design', 'Logos', 'Contact']

    React.useEffect(() => {
    
        const observer = new IntersectionObserver(entries => {
            
            entries.forEach(entry => {
                if (entry.isIntersecting && !visibleSections.includes(entry.target.id)) {
                    visibleSections.push(entry.target.id)
                }
                else if (!entry.isIntersecting && visibleSections.includes(entry.target.id)) {
                    const key = visibleSections.indexOf(entry.target.id)
                    visibleSections.splice(key, 1)
                }
            })
            
            if (visibleSections.length > 0) {
                if (visibleSections.length === 2) {
                    setVisibleSection('about')
                }
                else {
                    setVisibleSection(visibleSections[0])
                }            
            }
            
        }, { threshold: 1 })

        const sections = sectionIds.map(id =>
            document.getElementById(id)
        )

        sections.forEach(section => section && observer.observe(section))

        return () => {
            sections.forEach(section => section && observer.unobserve(section))
        }
    }, [])

    const goToAnchor = (id: string) => {
        const element = document.getElementById(id)
        if (element) {
            const y = element.getBoundingClientRect().top + window.pageYOffset - 36
            window.scrollTo({ top: y, behavior: 'smooth' })
        }
    }

    const baseStyle = `inline-block lg:block px-2 lg:ml-auto focus:outline-none focus:text-white`
    const activeStyle = baseStyle + 'text-white font-semibold'
    const inactiveStyle = baseStyle + 'hover:text-white'

    return (
        <div className="block lg:flex lg:flex-wrap lg:items-start lg:justify-between">
            <Header>
                <h1 className="text-white text-3xl lg:flex-grow uppercase">Hoopycake!</h1>
                <div className="mt-16 text-gray-300 text-sm leading-loose lg:flex-shrink-0">
                    <p className="-mx-2">
                        { sectionIds.map((id, index) => (
                            <button className={ visibleSection === id ? activeStyle : inactiveStyle }
                                key={ index }
                                onClick={ () => goToAnchor(id) }>
                                { sectionLabels[index] }
                            </button>
                        )) }
                    </p>
                    <p><Mailto className="hover:text-white group" href="info@hoopycake.com" type="mailto" icon={ mail } /></p>                
                </div>
            </Header>
            <main className="w-full py-8 px-6
                md:py-24 md:px-16
                lg:w-2/3 lg:ml-auto lg:py-32 xxl:w-larger"
                id="main-content">
                { children }
            </main>
        </div>
    )
}

export default Layout